<script lang="ts" setup>
const { td } = useI18n()

const { latestUpcomingOrder, totalOrders, cardContainerArgs, forYouArgs } =
  await useUpcomingOrders()

const MAX_CARD_NUMBER = 4

const { orderDetailsUrl } = useRouteHelper()

const visibleProducts = computed(
  () =>
    latestUpcomingOrder.value?.line_items &&
    latestUpcomingOrder.value?.line_items
      .filter(product => product.image_url ?? false)
      .slice(0, MAX_CARD_NUMBER)
)

const orderDate = computed(
  () =>
    latestUpcomingOrder.value?.placed_at &&
    td(latestUpcomingOrder.value.placed_at, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    })
)

const orderLineItemsLength = computed(
  () => latestUpcomingOrder.value?.line_items?.length ?? 0
)

const cardExtraItemsClass = computed(() =>
  visibleProducts.value && visibleProducts.value.length % 2 === 0
    ? '-mx-[1px]'
    : ''
)
</script>

<template>
  <MyAccountCardContainer v-bind="cardContainerArgs">
    <template #cardContent>
      <div
        v-if="latestUpcomingOrder && totalOrders > 0"
        class="bg-primitives-off-white gap-sm p-sm md:gap-y-sm flex flex-col flex-wrap justify-between md:flex-row"
      >
        <div class="text-light-6 flex flex-col gap-4 md:gap-6">
          <div class="flex flex-col gap-2">
            <p>
              <span class="text-book-6">
                {{ $ts('myAccount.upcomingOrders.orderNumber') }}
              </span>
              #{{ latestUpcomingOrder.number }}
            </p>
            <p>
              <span class="text-book-6">
                {{ $ts('myAccount.upcomingOrders.orderDate') }}
              </span>
              {{ orderDate }}
            </p>
          </div>
          <div class="flex">
            <NuxtLink
              v-for="item in visibleProducts"
              :key="item.id"
              class="w-xl md:w-3xl border-primitives-grey-100 border odd:-mx-[1px]"
              :to="orderDetailsUrl(latestUpcomingOrder.number ?? '')"
            >
              <NuxtPicture
                v-if="item.image_url"
                :key="item.id"
                :src="item.image_url!"
                provider="cloudinary"
                :alt="item.name ?? ''"
                class="block h-min"
                sizes="75px"
                fit="fill"
                loading="lazy"
              />
            </NuxtLink>
            <span
              v-if="orderLineItemsLength > MAX_CARD_NUMBER"
              :class="cardExtraItemsClass"
              class="text-link-6 w-xl md:w-3xl border-primitives-grey-100 -mx-[1px] flex items-center justify-center border border-l-0 pl-px"
            >
              +{{ orderLineItemsLength - MAX_CARD_NUMBER }}
            </span>
          </div>
        </div>
        <div
          v-if="latestUpcomingOrder.number"
          class="flex items-start justify-start md:justify-end"
        >
          <NuxtLink
            :to="orderDetailsUrl(latestUpcomingOrder.number)"
            class="text-link-6 underline"
          >
            {{ $ts('myAccount.upcomingOrders.orderDetails') }}
          </NuxtLink>
        </div>
      </div>
      <MyAccountForYouPlaceholder v-else v-bind="forYouArgs" />
    </template>
  </MyAccountCardContainer>
</template>

import type { LineItem } from '@commercelayer/sdk'
import type { MyAccountCardContainerProp } from '@design-system/components/MyAccount/MyAccountCardContainer.props'
import type { MyAccountForYouPlaceholderProps } from '@design-system/components/MyAccount/MyAccountForYouPlaceholder.props'

export const useUpcomingOrders = async () => {
  const { orders: ordersRoute, forYou } = useRouteHelper()
  const { ts } = useI18n()
  const { $cl } = useNuxtApp()
  const { userMeta, customerStatus } = await useCustomer()

  const { data: latestUpcomingOrder } = await useAsyncData(
    'LATEST_UPCOMING_ORDER',
    async () =>
      userMeta?.value?.commercelayer_id
        ? await $cl.customers.orders(userMeta.value.commercelayer_id, {
            include: ['shipments', 'line_items'],
            filters: {
              status_in: 'placing,placed,approved',
            },
            sort: ['-placed_at'],
            pageSize: 1,
          })
        : null,
    {
      server: false,
      transform: data => {
        const latestOrder = data?.[0]
        return {
          ...latestOrder,
          line_items:
            latestOrder?.line_items
              ?.filter(({ item_type }) => item_type === 'skus')
              .flatMap(el =>
                Array<LineItem>(el.quantity).fill({ ...el, quantity: 1 })
              ) ?? [],
          total: data?.meta.recordCount ?? 0,
        }
      },
    }
  )

  const totalOrders = computed(() => latestUpcomingOrder.value?.total ?? 0)

  const title = computed(() => {
    if (latestUpcomingOrder.value)
      return `${ts(`myAccount.upcomingOrders.titleUpcoming`)} ${totalOrders.value > 0 ? `(${totalOrders.value})` : ''}`
    return ts('myAccount.upcomingOrders.title')
  })

  const description = computed(() => {
    if (customerStatus === 'prospect')
      return ts('myAccount.upcomingOrders.descriptionNoOrders')
    if (!latestUpcomingOrder.value)
      return ts('myAccount.upcomingOrders.descriptionNoUpcomingOrders')
    return ''
  })

  const link = computed(() => {
    return customerStatus === 'prospect'
      ? { label: '', url: '' }
      : {
          label: ts('myAccount.upcomingOrders.link'),
          url: ordersRoute,
        }
  })

  const forYouArgs: MyAccountForYouPlaceholderProps = {
    title: ts('myAccount.forYou.title'),
    message: ts('myAccount.forYou.message'),
    cta: {
      label: ts('myAccount.forYou.link'),
      link: forYou,
    },
  }

  const cardContainerArgs = computed<MyAccountCardContainerProp>(() => ({
    header: {
      title: title.value,
      link: link.value,
    },
    description: description.value,
  }))

  return {
    latestUpcomingOrder,
    totalOrders,
    cardContainerArgs,
    forYouArgs,
  }
}
